import React, {useEffect} from 'react';
import SEO from '../components/seo';
import {navigate} from 'gatsby';

const IndexPage = () => {
  useEffect(() => {
    navigate('/pl');
  }, []);
  return <SEO title="Muzeum" />;
};

export default IndexPage;
